<template>
  <div>
    <v-container v-if="!loading" class="px-10 px-md-auto py-0">
      <v-list
        class="d-flex flex-column align-center"
        :class="!$vuetify.breakpoint.xs && 'align-div-content'"
      >
        <div class="mt-auto">
          <img
            :width="$vuetify.breakpoint.xs ? '150px' : '250px'"
            src="@/assets/icons/CheckmarkCircle.svg"
          />
        </div>
        <div class="text-h1 font-weight-bold secondary--text text-center">
          ¡Haz terminado con éxito tu registro en Stripe!
        </div>
        <div class="text-h3 my-6 px-5 px-md-16 mx-0 mx-md-14 text-center">
          Ya puedes aceptar solicitudes de tus servicios Zaturna, los pagos que
          recibas por tus servicios serán a través de la plataforma Stripe para
          brindarte la mayor seguridad.
        </div>
        <div class="d-flex justify-center flex-wrap mt-2 mb-10 mb-sm-0">
          <Button
            text="Ir a inicio"
            :disabled="loading"
            class="mb-2 mx-1"
            aria-label="Ir a la página de inicio de modo proveedor"
            url="ProviderHomepage"
          />
        </div>
        <div class="mt-auto mb-3">
          <img
            :width="$vuetify.breakpoint.xs ? '100px' : '150px'"
            src="@/assets/icons/providers/StripeLogo.svg"
          />
        </div>
      </v-list>
    </v-container>
    <v-container
      v-else
      class="d-flex flex-column align-div-content align-center"
    >
      <v-progress-circular
        indeterminate
        color="secondary"
        :size="100"
      ></v-progress-circular>
    </v-container>
  </div>
</template>
<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "SuccessStripeRegistration",
  components: {
    Button,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    await this.updateProviderStripeInfo();
    this.loading = false;
  },
  methods: {
    async updateProviderStripeInfo() {
      const providerId = this.$route.params.id;
      await this.$store.dispatch("retrieveProviderStripeInformation", {
        providerId: providerId,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    180deg,
    var(--v-primary-base) 0%,
    rgba(196, 196, 196, 0) 20%,
    #ffffff 100%
  );
  min-height: 100vh;
  background-size: cover !important;
}
.align-div-content {
  display: flex;
  justify-content: center;
  height: 100vh;
}
</style>
